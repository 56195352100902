<template>
  <div class="tabbar-item-box">
    <div v-for="(item, index) in items" :key="index" class="hand" @click="handleClickTab(item, index)">
      <span>{{ item.label }}</span>
      <div class="divider" :class="{ active: current === index }"/>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnNavbar',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    handleClickTab (item, index) {
      if (this.$store.getters.token) {
        if (this.items[0].label !== '美国' || this.isEnExpire) {
          this.$emit('handle-navbar', item)
          this.current = index
        } else {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar-item-box {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  margin-top: 20px;
  margin-left: 50px;
  font-size: 14px;
  font-weight: bold;
}
.tabbar-item-box > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.divider {
  height: 2px;
  width: 30px;
  margin-top:2px;
}
.active {
  background-color: #024579;
}
</style>
