<template>
  <div class="item-box">
    <div style="height: 20px"/>
    <div v-for="(item, index) in items" :key="index" @click="handleToggle(item, index)">
      <div :class="{ active: isCurrent1(item, index) }" class="item hand">
        <img v-if="isCurrent1(item, index)" :src="item.icon[1]">
        <img v-if="!isCurrent1(item, index)"  :src="item.icon[0]">
        <span :style="{color:(item.value === 'MarketAnalysis' ? '#333333' : '#333333')}">{{ item.label }}</span>
        <div :class="{ 'arrow-down': item.isChange, 'ac-2': item.isChange, hide: item.children.length === 0 }" class="btc-arrow arrow-up ac-1" ></div>
        <!-- <div :class="{ 'arrow-down': isCurrent2(item, index), 'ac-2': isCurrent(item, index), hide: item.children.length === 0 }" class="btc-arrow arrow-up ac-1" ></div> -->
      </div>
      <div>
        <div v-show="item.isChange" v-for="(child, cindex) in item.children" :key="child.value" class="item-child" @click.stop="handleClickItem(child, cindex, index)">
          <dotline :is-last="child.isChange" :is-active="child.isChange" :childLabel="child.label"/>
          <span class="hand" :class="{ cactive: sortNum === child.sort }">{{ child.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dotline from './components/dotline'

export default {
  name: 'CnBtcDataSidebar',
  components: {
    Dotline
  },
  data () {
    return {
      allOpen: true,
      current: 0,
      currentChild: 0,
      items: [
        {
          sort: 0,
          label: '实时行情',
          value: 'MarketPrice',
          icon: [
            require('@/assets/image/price/price2.png'),
            require('@/assets/image/price/price21.png')
          ],
          children: [
            { sort: 10, label: '现货', label2: '现货', value: '_marketSpot', isChange: true },
            { sort: 11, label: '期货', value: '_marketFutures', isChange: false }
          ],
          isChange: true
        },
        {
          sort: 1,
          label: '行情分析',
          value: 'MarketAnalysis',
          icon: [
            require('@/assets/image/cn/fire_cn.png'),
            require('@/assets/image/cn/fire_cn.png')
          ],
          children: [
            { sort: 100, label: '日行情', value: 'daily', isChange: false },
            { sort: 101, label: '周行情', value: 'weekly', isChange: false }
          ],
          isChange: true
        },
        {
          sort: 2,
          label: '行情走势',
          value: 'PriceIndex',
          icon: [
            require('@/assets/image/price/price1.png'),
            require('@/assets/image/price/price11.png')
          ],
          children: [
            { sort: 200, label: '牛肉', value: '_beef', isChange: false },
            { sort: 201, label: '活牛', value: '_liveCattle', isChange: false }
          ],
          isChange: true
        },
        {
          sort: 3,
          label: '涨跌榜',
          value: 'UpsAndDowns',
          icon: [
            require('@/assets/image/price/price3.png'),
            require('@/assets/image/price/price31.png')
          ],
          children: [
            { sort: 300, label: '现货', value: '_upDownSpot', isChange: false },
            { sort: 301, label: '期货', value: '_upDownFutures', isChange: false }
          ],
          isChange: true
        }
      ],
      sortNum: 10,
      isShow: true,
      isChange: -1
    }
  },
  methods: {
    isCurrent2 (item, index) {
      if (this.allOpen) {
        if (item.sort === 3) {
          return false
        }
        return true
      }
      return this.current === index
    },
    isCurrent1 (item, index) {
      if (this.allOpen && this.current === index) {
        return true
      }
      if (!this.allOpen && this.current === index) {
        return true
      }
      return false
    },
    isCurrent (item, index) {
      if (this.allOpen) {
        return true
      }
      return this.current === index
    },
    handleToggle (item, index) {
      this.sortNum = -1
      this.allOpen = false
      this.currentChild = -1
      // this.current = index
      this.showFalse()
      if (index === 3) {
        item.isChange = !item.isChange
        this.items[index].children[0].label2 = item.label
        this.items[index].children[0].isChange = !this.items[index].children[0].isChisChangea
        this.$emit('tab-inner', this.items[index].children[0])
      } else {
        if (this.current === index) {
          this.isShow = false
          item.isChange = !item.isChange
          this.items[index].children[0].isChange = !this.items[index].children[0].isChange
        } else {
          this.isShow = true
          item.isChange = true
          this.items[index].children[0].label2 = item.label
          this.items[index].children[0].isChange = true
          console.log(this.items)
          this.$emit('tab-inner', this.items[index].children[0])
        }
      }
      // this.isChange = item.sort
      // this.current = this.current !== index ? index : -1
      // this.$emit('tab-outer', item)
      this.current = index
    },
    handleClickItem (item, cindex, index) {
      this.showFalse()
      this.sortNum = item.sort
      this.currentChild = cindex
      this.items[index].children[cindex].label2 = item.label
      this.items[index].children[cindex].isChange = true
      this.$emit('tab-inner', item)
    },
    showFalse () {
      this.items.forEach((item, index) => {
        item.children.forEach((item2, index2) => {
          item2.isChange = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$dot-line-color: #e8e8e7;

.item-box{
  color: #666666;
}

.item {
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  font-size: 14px;
}

.item > img {
  width: 19px;
  height: 16px;
}

.item > span {
  width: 160px;
}

.item-child {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-left: 15px;
  font-size: 14px;
}

.item-child:first-child {
  margin-top: 8px;
}

.item-child > span {
  margin-left: 20px;
}

.active {
  color: #024579;
  background-color: #e4f2ff;
}

.cactive{
  color: #333333;
  font-size: 14px;
  font-weight: bold;
}
</style>
