<template>
  <div class="dot-line">
    <div class="line"></div>
    <div class="dot" :class="{ active: isActive && (childLabel === '牛肉' || childLabel === '活牛'),
    active1: isActive && childLabel === '现货',
    active2: isActive && childLabel === '期货',
    active3: isActive && childLabel === '日行情',
    active4: isActive && childLabel === '周行情' }"></div>
    <div class="line" :class="{ hide: isLast }"></div>
  </div>
</template>

<script>
export default {
  name: 'CnBtcDataDotline',
  props: {
    isLast: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    childLabel: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
$dot-line-color: #e8e8e7;

.dot-line {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: $dot-line-color;
  margin: 5px 0;
}

.line {
  width: 2px;
  height: 10px;
  background: $dot-line-color;
}

.active {
  background: #024579;
}

.active1 {
  background:linear-gradient(90deg,#2b915c 50%,#e27516 50%);
}

.active2 {
  background: #c10b0b;
}

.active3 {
  background: #024579;
}
.active4 {
  background: #024579;
}
</style>
