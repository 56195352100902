<template>
  <div class="content-box">
    <div class="content-main">
      <div class="content-main-left">
        <sidebar @tab-outer="handleTabOuter" @tab-inner="handleTabInner"/>
      </div>
      <div class="content-main-right">
        <price-price-index v-if="current==='_beef'" />
        <price-live-cattle v-if="current==='_liveCattle'" />
        <price-market-price v-if="current==='_marketSpot'"/>
        <price-market-price-futures v-if="current==='_marketFutures'"/>
        <price-ups-downs v-if="current==='_upDownSpot'"/>
        <price-ups-downs-futures v-if="current==='_upDownFutures'"/>
        <price-market-analysis v-if="current==='weekly' && !isMore" :default-items="defaultItems" :selected="selectedNav" :key="selectedNav.value" @handleMore="handleMore"/>
        <price-market-analysis-day v-if="current==='daily' && !isMore" :default-items="defaultItems" :selected="selectedNav" :key="selectedNav.value" @handleMore="handleMore"/>
        <!-- <price-market-analysis-more v-if="current==='weekly' && !isMore" :default-items="defaultItems" :selected="selectedNav" :key="selectedNav.value"  @handleNoMore="handleNoMore"/>
        <price-market-analysis-day v-if=" current==='daily' && !isMore" :default-items="defaultItems1" :selected="selectedNav" :key="selectedNav.value"  @handleNoMore="handleNoMore"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/sidebar'
import PricePriceIndex from './price-price-index'
import PriceLiveCattle from './price-live-cattle'
import PriceMarketPrice from './price-market-price'
import PriceMarketPriceFutures from './price-market-price-futures'
import PriceUpsDowns from './price-ups-downs'
import PriceUpsDownsFutures from './price-ups-downs-futures'
import PriceMarketAnalysis from './price-market-anglysis'
import PriceMarketAnalysisDay from './price-market-anglysis/dayindex'
// import PriceMarketAnalysisMore from './price-market-anglysis/components/week-more'
// import PriceMarketAnalysisDay from './price-market-anglysis/components/day-more'

export default {
  name: 'CnHome',
  components: {
    Sidebar,
    PricePriceIndex,
    PriceLiveCattle,
    PriceMarketPrice,
    PriceMarketPriceFutures,
    PriceUpsDowns,
    PriceUpsDownsFutures,
    // PriceMarketAnalysisDay,
    PriceMarketAnalysisDay,
    PriceMarketAnalysis
  },
  data () {
    return {
      outer: '',
      current: '_marketSpot',
      isMore: false,
      defaultItems: [],
      defaultItems1: [],
      selectedNav: ''
    }
  },
  methods: {
    handleTabOuter (item) {
      this.outer = item.value
      if (this.outer === 'MarketAnalysis') {
        this.isMore = false
        this.current = this.outer
        this.defaultItems = []
      }
    },
    handleTabInner (item) {
      this.current = item.value
      console.log(this.current)
      //  此处拿到了sidebar穿过来的item
      this.selectedNav = item.value
    },
    handleMore () {
      this.isMore = true
    },
    handleNoMore (items) {
      this.defaultItems = items
      this.isMore = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.content-main {
  width: 1248px;
  display: flex;
}

.content-main-left {
  width: 240px;
  min-height: 800px;
  background-color: #ffffff;
}

.content-main-right {
  flex: 1;
}
</style>
