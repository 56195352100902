<template>
  <div>
    <btc-nav-bar :items="items" :is-en-expire="isEnExpire" @handle-navbar="handleNavBar"/>
    <div class="item-box">
      <btc-chart v-if="list" ref="liveCattleChart" :data="chartData" :title='title' :color="chartColor" :legend="legend" :y-axis-name="yAxisName" :is-en-expire="isEnExpire" @getChartX="getDateAndPrice"/>
      <div class="item-box" style="padding: 30px;">
        <btc-table :country="country" :tableList="tableList" :items="tableHeader"/>
      </div>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table/live-cattle'
import BtcChart from './components/chart/index-new'
import BtcNavBar from './components/navbar'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'CnExportsToChina',
  components: {
    BtcTable,
    BtcChart,
    BtcNavBar
  },
  data () {
    return {
      items: [
        { label: '美国', value: '美国' },
        { label: '巴西', value: '巴西' },
        { label: '澳大利亚', value: '澳大利亚' },
        { label: '乌拉圭', value: '乌拉圭' },
        { label: '阿根廷', value: '阿根廷' }
      ],
      tableHeader: [
        { label: '时间', icon: require('@/assets/image/price/Date.png') },
        { label: '价格', icon: require('@/assets/image/price/currency.png') },
        { label: '涨跌', icon: require('@/assets/image/price/Trend.png') }
      ],
      tableList: [],
      chartData: {},
      chartColor: { Color1: '#3877f2', Color2: '#FFFFFF' },
      legend: {
        orient: 'vertical',
        icon: 'roundRect',
        itemWidth: 10,
        itemHeight: 10,
        right: 100,
        top: 15,
        selectedMode: false
      },
      country: '美国',
      yAxisName: '',
      title: '美国活牛价格走势',
      list: null
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    isEnExpire () {
      return this.getUserProfile(this.$t('message._beefpriceindex'))
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPriceLivecattleIndex', {
        UserID: getLocalStore().UserID,
        Country: this.country,
        isEn: false
      }).then(() => {
        this.list = this.$store.getters.priceRow
        this.riseList = this.list.riseList
        this.chartData = { categories: this.list.categories, series: this.getSeries(this.list.series) }
        if (this.$refs.liveCattleChart) {
          this.$refs.liveCattleChart.refreshData({ categories: this.list.categories, series: this.getSeries(this.list.series) })
        }
        this.getDateAndPrice(this.list.categories.slice(-1)[0])
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getDateAndPrice (category) {
      const rise = []
      for (let i = 0; i < this.list.series.length; i++) {
        const item = this.list.series[i]
        if (item.data && item.data.length > 0) {
          for (let i = 0; i < this.riseList.length; i++) {
            const end = this.riseList[i][0].key.indexOf('-')
            const key = this.riseList[i][0].key.substring(0, end)
            if (key === item.name) {
              const riseFilterList = this.riseList[i].filter(v => {
                return v.key === item.name + '-' + category
              })
              if (riseFilterList.length) {
                rise.push(this.riseList[i].filter(v => {
                  return v.key === item.name + '-' + category
                }).map(v => {
                  return { date: category, category: item.name, currency: item.data[this.list.categories.indexOf(category)], trend: v.rise }
                })[0])
              } else {
                rise.push({ date: category, category: item.name, currency: item.data[this.list.categories.indexOf(category)] ? item.data[this.list.categories.indexOf(category)] : '--', trend: '--' })
              }
            }
          }
        }
      }
      this.tableList = rise
    },
    handleNavBar (item) {
      this.country = item.value
      this.handleTableHeader()
      this.handleTitle()
      this.getData()
    },
    getSeries (data) {
      return data.map(v => {
        v.type = 'line'
        return v
      })
    },
    handleTableHeader () {
      switch (this.country) {
        case '巴西':
          this.tableHeader = [
            { label: '时间', icon: require('@/assets/image/price/Date.png') },
            { label: '价格', icon: require('@/assets/image/price/currency.png') },
            { label: '走势', icon: require('@/assets/image/price/Trend.png') }
          ]
          this.title = '巴西活牛胴体均价走势'
          break
        case '澳大利亚':
        case '乌拉圭':
        case '阿根廷':
          this.tableHeader = [
            { label: '时间', icon: require('@/assets/image/price/Date.png') },
            { label: '类别', icon: require('@/assets/image/price/category.png') },
            { label: '美元/公斤', icon: require('@/assets/image/price/USD.png') },
            { label: '涨跌', icon: require('@/assets/image/price/Trend.png') }
          ]
          break
        case '美国':
          this.tableHeader = [
            { label: '时间', icon: require('@/assets/image/price/Date.png') },
            { label: '美元/公斤', icon: require('@/assets/image/price/USD.png') },
            { label: '涨跌', icon: require('@/assets/image/price/Trend.png') }
          ]
          break
        default:
          return []
      }
    },
    handleTitle () {
      switch (this.country) {
        case '巴西':
          this.yAxisName = ''
          this.title = '巴西活牛胴体均价走势'
          break
        case '澳大利亚':
          this.yAxisName = 'USD/kg lwt'
          this.title = '澳大利亚活牛价格走势'
          break
        case '乌拉圭':
          this.yAxisName = 'USD/kg lwt'
          this.title = '乌拉圭活牛价格走势'
          break
        case '阿根廷':
          this.yAxisName = 'USD/kg lwt'
          this.title = '阿根廷活牛价格走势'
          break
        case '美国':
          this.yAxisName = ''
          this.title = '美国活牛价格走势'
          break
        default:
          this.yAxisName = ''
          return '美国活牛价格走势'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

// 筛选
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
